const frenchReviews = [
  // GOOGLE REVIEWS
  {
    author_name: "PHILIPPE MORAND",
    author_url:
      "https://www.google.com/maps/contrib/114472659869150736507/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AAcHTtfwta7UGFtVmxD3KKBdD8djZ5z2HQ6FQ9OvVm67=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "il y a un mois",
    text: "Facile d’accès 24h/24h, pas d’attente avec borne automatique et CB, vous payez la 1ère nuit, si vous restez plusieurs jours vous paierez le nombre de nuit totale en sortant, très simple.\nLe lieu est très calme, avec le bout du lac, et à 200m de la voie verte: Annecy à Albertville et plus.\nVous êtes a la campagne avec vue sur les Alpes magnifiques.",
    time: 1683205412,
    translated: false,
  },
  {
    author_name: "Pat Patrouille",
    author_url:
      "https://www.google.com/maps/contrib/102545080452791939920/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AAcHTteVMQP4RSIn9yYkom4YfLRgxF_fjEdbj2roHshd=s128-c0x00000000-cc-rp-mo",
    rating: 3,
    relative_time_description: "il y a 9 mois",
    text: "Aire correcte pour une étape, calme bien desservie avec piste cyclable, seul bémol un peu trop proche les uns des autres",
    time: 1661242698,
    translated: false,
  },
  {
    author_name: "Sylvia Quatrevaux",
    author_url:
      "https://www.google.com/maps/contrib/105818147757872907858/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AAcHTteRlm1XmuxK-Ir16N8q2HyHh-6wDkUm72VRzowd=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "il y a 2 semaines",
    text: "Endroit très calme,\npropre et pratique,  emplacement spacieux, à recommander.",
    time: 1685118409,
    translated: false,
  },
  {
    author_name: "Chrt Mrt",
    author_url:
      "https://www.google.com/maps/contrib/108470089312769266018/reviews",
    language: "fr",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a-/AD_cMMQQipcVkb2DLtn-txTBUhU76zbtSDAn_Jr_xAJxPw=s128-c0x00000000-cc-rp-mo-ba4",
    rating: 5,
    relative_time_description: "il y a 10 mois",
    text: "Jolie aire avec tous les services, calme et dans la verdure.\nVue magnifique sur les montagnes qui entourent le lac.\nProche de la piste cyclable pour aller à Annecy.\nPropriétaire sympathique.\nNous y reviendrons sûrement.",
    time: 1659116945,
    translated: false,
  },

  // PARK4NIGHT REVIEWS
  {
    author_name: "nabe13",
    author_url: "https://park4night.com/fr/user/nabe13",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 5,
    text: "tout était parfait, les emplacements, la proximité de la piste cyclable et du lac, les services bien étudiés(vidange, eau etc..) surtout continuez à bien entretenir votre aire.",
    time: 1776758037,
  },
  {
    author_name: "lesjoemi",
    author_url: "https://park4night.com/fr/user/lesjoemi",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_p.png?v=b30ff3b",
    rating: 4,
    text: "Aire très agréable avec tous les services, nous avons passé 2 superbes nuits. Plage et voie verte pas loin. Dommage, certains emplacements sont un peu pentus. Tarif correct.",
    time: 1776758038,
  },
  {
    author_name: "mayork",
    author_url: "https://park4night.com/fr/user/mayork",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 5,
    text: "Nous sommes actuellement sur cette aire privée. Ne vous embêtez pas à chercher une aire sur Annecy, vous ne trouverez pas mieux sur l’agglomération. Les camping-cars ne sont pas les bienvenus à Annecy. On vous renvoie sur les campings qui sont hors de prix. Les propriétaires sont très accueillants et serviables. Ils vous dépannent au besoin. Je ne peux que vous encourager à vous installer ici. Mériterait une meilleure signalisation. Il faut passer sous un pont sur lequel passe la voie verte, il est étroit mais un tracteur passe, puis il faut continuer 200 à 300 m",
    time: 1776758039,
  },
  {
    author_name: "poupy26",
    author_url: "https://park4night.com/fr/user/poupy26",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 4,
    text: "Merci au propriétaire pour cette aire très agréable avec tous services y compris Wi-Fi à proximité d Annecy soit en scooter soit a vélo et la plage à 10 minutes à pieds merci de préserver cet endroit quand vous y séjournez prix très correct pour la région",
    time: 1776758040,
  },
];

export default frenchReviews;
