const englishTexts = [
  // ABOUT
  {
    name: "aboutTitle",
    text: "L'Hirondelle - motorhome park welcomes you",
  },
  {
    name: "aboutContent",
    text: `Created in the early 2000s by my parents, who are keen on travelling by motorhome, the the area has evolved each year to allow you to spend a pleasant to spend a pleasant and relaxing moment, while remaining in the simplicity and conviviality.<br />
    All pitches are on grass and relatively flat, sunny and delimited with a great view on the mountains`,
  },
  // TARIFS
  {
    name: "tarifsTitle",
    text: "TARIFS",
  },
  {
    name: "tarifsTitle",
    text: "TARIFS",
  },
  {
    name: "tarifsParking",
    text: "24 HOURS PARKING",
  },
  {
    name: "tarifsParkingSubtitle",
    text: "SERVICES AND TOURIST TAX INCLUDED",
  },
  {
    name: "tarifsLowDates",
    text: "04/01 - 06/15 <br />09/15 - 11/01",
  },
  {
    name: "tarifsLowPrice",
    text: "18€",
  },
  {
    name: "tarifsPeakDates",
    text: "06/16 - 09/14",
  },
  {
    name: "tarifsPeakPrice",
    text: "20€",
  },
  // SERVICES
  {
    name: "servicesTitle",
    text: "SERVICES",
  },
  {
    name: "plugService",
    text: `All pitches have a 4A (880 watts) power socket, no air conditioning or other energy consuming appliances possible, only for charging your cell.`,
  },
  {
    name: "wifiService",
    text: `You will have access to wifi with a very good speed, the access code will be given to you on your ticket at the entrance`,
  },
  {
    name: "waterService",
    text: `One hose on each side for easy filling without having to manoeuvre`,
  },
  {
    name: "cardService",
    text: `Entry and exit to the area is via an automatic blue card terminal. You will find a clear explanation panel to allow you to enter the area`,
  },
  {
    name: "recyclingService",
    text: `The area has selective sorting containers as well as a composter`,
  },
  {
    name: "toiletService",
    text: `A tank for emptying chemical toilets with flushing and a flushing tank`,
  },
  // NEARBY
  {
    name: "nearbyTitle",
    text: "NEARBY",
  },
  {
    name: "jardinTailleferTitle",
    text: "Jardin du Taillefer",
  },
  {
    name: "jardinTailleferContent",
    text: `Located in the middle of the family farmland, you will find on the spot twice a week a sale of organic vegetables (Monday and Thursday from 4.30 pm to 7.30 pm) as well as a 100% local vegetarian restaurant <br /> <a href="https://www.jardins-du-taillefer.fr" target='_blank' rel='noopener noreferrer'>See the website</a>`,
  },
  {
    name: "hikingTitle",
    text: "Hiking",
  },
  {
    name: "hikingContent",
    text: `Many walks starting from the area in order to get some height and admire the lake or to discover the nature reserve at the end of the lake`,
  },
  {
    name: "bikeTitle",
    text: "Bike Path",
  },
  {
    name: "bikeContent",
    text: `The area is very quiet: close to the Annecy-Albertville cycle path and  from the first beach on Annecy lake`,
  },
  // REVIEW
  {
    name: "reviewTitle",
    text: "YOUR REVIEWS",
  },
  {
    name: "seeMoreReviews",
    text: "SEE MORE REVIEWS",
  },
  // FOOTER
  {
    name: "signatureFooter",
    text: `<a href="https://sanb.fr" target='_blank' rel='noopener noreferrer'>Website by Sandro Brignoli 💭</a>`,
  },
];

export default englishTexts;
