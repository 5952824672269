import { Stack, Box } from "@mui/system";
import Text from "./Text";
import TarifsCard from "./TarifsCard";

const Tarifs = () => {
  const prices = [
    {
      dates: "tarifsLowDates",
      price: "tarifsLowPrice",
    },
    {
      dates: "tarifsPeakDates",
      price: "tarifsPeakPrice",
    },
  ];

  return (
    <Stack sx={{ p: "1rem" }}>
      <Box
        sx={{
          backgroundColor: "primary.main",
          m: "auto",
          p: "1rem",
          width: { xs: "90%", md: "60%", lg: "50%" },
          borderRadius: "20px",
          textAlign: "center",
        }}
      >
        <Text textName='tarifsParking' variant='h4' color='white' />
        <Text textName='tarifsParkingSubtitle' variant='h6' color='white' />
        <TarifsCard infos={prices} />
      </Box>
    </Stack>
  );
};

export default Tarifs;
