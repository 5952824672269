import { useState, useEffect, useRef } from "react";

import ReactPlayer from "react-player/youtube";

const BackgroundVideo = ({ videoUrl, fallbackImage }) => {
  const [displayVideo, setDisplayVideo] = useState("none");
  const videoRef = useRef(null);

  const handleVideoReady = (playedSeconds) => {
    if (
      playedSeconds > 4.1 &&
      window.innerWidth > 1470 &&
      displayVideo === "none"
    ) {
      setDisplayVideo("flex");
    }
  };

  const handleResize = () => {
    if (videoRef.current.getCurrentTime() > 4) {
      setDisplayVideo(window.innerWidth < 1470 ? "none" : "flex");
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        overflow: "hidden",
      }}
    >
      {displayVideo === "none" && (
        <img
          src={fallbackImage}
          alt='Background'
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
        />
      )}
      <div style={{ height: "100%", width: "100%" }}>
        <ReactPlayer
          ref={videoRef}
          url={videoUrl}
          loop
          muted
          playing
          playbackRate={1.75}
          width={"100%"}
          height={"130%"}
          style={{
            display: displayVideo,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          onProgress={({ playedSeconds }) => handleVideoReady(playedSeconds)}
          config={{
            youtube: {
              playerVars: {
                playsinline: 0,
                start: 4,
                end: 50,
                controls: 0,
                showinfo: 0,
                modestbranding: 1,
                rel: 0,
                disablekb: 1,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default BackgroundVideo;
