import { Divider } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AOS from "aos";
import "aos/dist/aos.css";

import "./App.css";
import About from "./components/About";
import Footer from "./components/footer/Footer";
import Hero from "./components/Hero";
import LanguageIcon from "./components/LanguageIcon";
import Nearby from "./components/Nearby";
import Partners from "./components/Partners";
import Review from "./components/Review";
import Services from "./components/Services";
import Tarifs from "./components/Tarifs";

function App() {
  AOS.init();
  const theme = createTheme({
    palette: {
      primary: {
        main: "#13A89E",
      },
      secondary: {
        main: "#404041",
      },
    },
    typography: {
      fontFamily: "MontSerrat",
    },
    breakpoints: {
      values: { xs: 0, sm: 600, md: 1200, lg: 1400, xl: 1600 },
    },
  });

  return (
    <div className='container'>
      <ThemeProvider theme={theme}>
        <LanguageIcon />
        <Hero />
        <About />
        <Tarifs />
        <Services />
        <Nearby />
        <Review />
        <Divider />
        <Partners />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
