import { Stack, Box } from "@mui/system";

const Partners = () => {
  return (
    <Stack
      sx={{
        width: { xs: "90%", md: "70%" },
        m: "0 auto 1rem ",
        p: "1rem",
        flexDirection: { xs: "column", md: "row" },
        gap: { xs: "2rem", md: "1rem" },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src='./images/logo_AuRA.png'
          alt='La Région Auverge-Rhône-Alpes'
          width={"100%"}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src='./images/reseau_initiative.png'
          alt='Réseau Initiative Grand Annecy'
          width={"60%"}
        />
      </Box>
    </Stack>
  );
};

export default Partners;
