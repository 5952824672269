import { useState, useEffect } from "react";
import { Stack, Box } from "@mui/system";

import TitleCard from "./TitleCard";
import Text from "./Text";
import ReactPlayer from "react-player/youtube";

const About = () => {
  const [displayVideo, setDisplayVideo] = useState(false);

  const handleResize = () => {
    const screenWidth = window.innerWidth;
    setDisplayVideo(screenWidth < 1470 ? true : false);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Stack
      backgroundColor='primary.main'
      direction='column'
      overflow='hidden'
      pb='1rem'
    >
      <TitleCard
        text='aboutTitle'
        textType='h5'
        backgroundColor='primary.main'
        width='auto'
      />
      <Stack
        m='auto'
        sx={[
          {
            p: {
              xs: "0.5rem",
              sm: "2rem",
            },
            gap: {
              xs: "2rem",
              lg: "10rem",
            },
            flexDirection: {
              md: "column",
              lg: "row",
            },
            alignItems: "center",
          },
        ]}
      >
        <Box
          data-aos='fade-right'
          sx={[
            {
              width: { xs: "18rem", sm: "30rem" },
            },
          ]}
          height='18rem'
          m='auto'
        >
          {displayVideo ? (
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=l75a9ZLQpv0`}
              loop
              muted
              playing
              playbackRate={1.75}
              width={"100%"}
              height={"100%"}
              config={{
                youtube: {
                  playerVars: {
                    playsinline: 0,
                    start: 4,
                    end: 50,
                    controls: 0,
                    showinfo: 0,
                    modestbranding: 1,
                    rel: 0,
                    disablekb: 1,
                  },
                },
              }}
            />
          ) : (
            <img
              src='./images/about.png'
              alt='à propos'
              width='100%'
              height='100%'
            />
          )}
        </Box>
        <Text
          dataAos='fade-left'
          textName='aboutContent'
          sx={[
            {
              width: { xs: "20rem", sm: "30rem" },
              color: "white",
            },
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default About;
