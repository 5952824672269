import { Stack } from "@mui/system";
import NearbyCard from "./NearbyCard";
import TitleCard from "./TitleCard";

const Nearby = () => {
  return (
    <Stack>
      <TitleCard textType='h5' text='nearbyTitle' />
      <NearbyCard
        image='jardin-taillefer.png'
        title='jardinTailleferTitle'
        text='jardinTailleferContent'
      />
      <NearbyCard
        image='hiking.jpg'
        title='hikingTitle'
        text='hikingContent'
        reverse
      />
      <NearbyCard image='bike.jpg' title='bikeTitle' text='bikeContent' />
    </Stack>
  );
};

export default Nearby;
