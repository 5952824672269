import { Card, CardContent } from "@mui/material";
import InfoItem from "./InfoItem";
import RoomIcon from "@mui/icons-material/Room";
import CallIcon from "@mui/icons-material/Call";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";

const Info = () => {
  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: "primary.main",
        zIndex: 2,
        m: "0 auto",
        display: "flex",
        p: { md: "0", lg: "0.5rem 0" },
        borderRadius: { xs: "0", lg: "5rem" },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          m: "auto",
          gap: { md: "0", lg: "0.3rem" },
          p: { sm: "0.5rem", lg: "0.5rem" },
        }}
      >
        <InfoItem icon={GpsFixedIcon} text={`45°47'41.2"N 6°12'28.7"E"`} />
        <InfoItem
          icon={RoomIcon}
          text='190 route de la porte, 74210 Lathuile'
        />
        <InfoItem icon={CallIcon} text='07 83 81 65 78' />
        <InfoItem
          icon={AlternateEmailIcon}
          text='leshirondellesdulac@gmail.com'
          isMail
        />
      </CardContent>
    </Card>
  );
};

export default Info;
