import { Box, Stack } from "@mui/system";
import React from "react";
import Text from "./Text";

const TarifsCard = ({ infos }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1.5rem",
        backgroundColor: "white",
        borderRadius: "20px",
        width: "fit-content",
        p: { xs: "1rem ", sm: "1rem 4rem" },
        m: "1rem auto",
      }}
    >
      {infos.map((info) => (
        <React.Fragment key={info.dates}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1.5rem" }}>
            <Box sx={{ alignSelf: "center" }}>
              <Text textName={info.dates} variant='h5' />
            </Box>
            <Box sx={{ alignSelf: "center" }}>
              <Text textName={info.price} variant='h3' />
            </Box>
          </Box>
          {infos.indexOf(info) !== infos.length - 1 ? (
            <Box
              sx={{
                width: "10rem",
                height: "2px",
                backgroundColor: "black",
                m: "auto",
              }}
            ></Box>
          ) : null}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default TarifsCard;
