import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Box } from "@mui/system";

import { changeLanguage } from "../store/language/language-slice";

const LanguageIcon = () => {
  const dispatch = useDispatch();
  const language = useSelector((store) => store.LANGUAGE.language);

  const [otherLanguage, setOtherLanguage] = useState("english");

  const languageToggle = () => {
    if (language === "french") {
      dispatch(changeLanguage("english"));
      setOtherLanguage("french");
    } else {
      dispatch(changeLanguage("french"));
      setOtherLanguage("english");
    }
  };

  return (
    <Box
      sx={{
        m: { xs: "0.5rem", sm: "1rem", lg: "2rem" },
        position: "fixed",
        zIndex: 10,
        cursor: "pointer",
      }}
      onClick={languageToggle}
    >
      <img
        src={`./${otherLanguage}.png`}
        alt='langue'
        width='50px'
        height='50px'
      />
    </Box>
  );
};

export default LanguageIcon;
