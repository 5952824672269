import Text from "../Text";
import s from "./style.module.css";

const Footer = () => {
  return (
    <>
      <div className={s.line}></div>
      <div className={s.footer}>
        <div className={s.footer__signature}>
          <Text textName='signatureFooter' />
        </div>
      </div>
    </>
  );
};

export default Footer;
