import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import { useState } from "react";

const Map = ({ children }) => {
  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  };

  const { isLoaded } = useJsApiLoader({
    id: "hirondelle-du-lac",
    googleMapsApiKey: "AIzaSyCPvfyhXX_ocb7obJdFgHzha9vVaU-VnLk",
  });

  const [center] = useState({
    lat: 45.79455803092737 - 0.0013,
    lng: 6.207529517805144,
  });

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={15}
          fullscreenControl={false}
          streetViewControl={false}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: false,
            maxZoom: 18,
            minZoom: 13,
          }}
        >
          <MarkerF
            position={{ lat: 45.79455803092737, lng: 6.207529517805144 }}
            animation={2}
          />
          {children}
        </GoogleMap>
      ) : null}
    </>
  );
};

export default Map;
