import { Box } from "@mui/system";
import Text from "./Text";

const TitleCard = ({
  text,
  textType = "p",
  backgroundColor = "secondary.main",
  color = "white",
  width = "auto",
}) => {
  return (
    <Box
      m='1rem auto'
      p='1rem'
      backgroundColor={backgroundColor}
      borderRadius='20px'
      textAlign='center'
      sx={[
        {
          width: { xs: "auto", sm: width },
        },
      ]}
    >
      <Text textName={text} variant={textType} color={color}></Text>
    </Box>
  );
};

export default TitleCard;
