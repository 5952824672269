import Grid from "@mui/material/Unstable_Grid2";
import { Stack } from "@mui/system";
import ServiceCard from "./ServiceCard";
import TitleCard from "./TitleCard";

const Services = () => {
  return (
    <Stack>
      <TitleCard textType='h5' text='servicesTitle' />
      <Grid with='100%' container>
        <Grid
          item
          display='flex'
          justifyContent='center'
          textAlign='center'
          p='2rem'
          sm={6}
          md={4}
          lg={2}
        >
          <ServiceCard icon='prise' text='plugService' />
        </Grid>
        <Grid
          item
          display='flex'
          justifyContent='center'
          textAlign='center'
          p='2rem'
          xs={12}
          sm={6}
          md={4}
          lg={2}
        >
          <ServiceCard icon='wifi' text='wifiService' />
        </Grid>
        <Grid
          item
          display='flex'
          justifyContent='center'
          textAlign='center'
          p='2rem'
          xs={12}
          sm={6}
          md={4}
          lg={2}
        >
          <ServiceCard icon='eau' text='waterService' />
        </Grid>
        <Grid
          item
          display='flex'
          justifyContent='center'
          textAlign='center'
          p='2rem'
          xs={12}
          sm={6}
          md={4}
          lg={2}
        >
          <ServiceCard icon='cb' text='cardService' />
        </Grid>
        <Grid
          item
          display='flex'
          justifyContent='center'
          textAlign='center'
          p='2rem'
          xs={12}
          sm={6}
          md={4}
          lg={2}
        >
          <ServiceCard icon='recyclage' text='recyclingService' />
        </Grid>
        <Grid
          item
          display='flex'
          justifyContent='center'
          textAlign='center'
          p='2rem'
          xs={12}
          sm={6}
          md={4}
          lg={2}
        >
          <ServiceCard icon='vidange' text='toiletService' />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Services;
