const frenchTexts = [
  // ABOUT
  {
    name: "aboutTitle",
    text: "L'Hirondelle, Aire de camping-car vous souhaite la bienvenue",
  },
  {
    name: "aboutContent",
    text: `Créée au début des années 2000 par mes parents adeptes des voyages en
      camping-car, l'aire a évolué chaque année pour vous permettre de
      passer un moment agréable et reposant, tout en restant dans la
      simplicité et la convivialité. <br />
      Tous les emplacements sont sur l’herbe et relativement plats, bien
      ensoleillés et délimités avec une vue dégagée sur les montagnes.`,
  },
  // TARIFS
  {
    name: "tarifsTitle",
    text: "TARIFS",
  },
  {
    name: "tarifsTitle",
    text: "TARIFS",
  },
  {
    name: "tarifsParking",
    text: "PARKING 24H",
  },
  {
    name: "tarifsParkingSubtitle",
    text: "SERVICES ET TAXES DE SÉJOUR INCLUS",
  },
  {
    name: "tarifsLowDates",
    text: "01/04 - 15/06 <br />15/09 - 01/11",
  },
  {
    name: "tarifsLowPrice",
    text: "18€",
  },
  {
    name: "tarifsPeakDates",
    text: "16/06 - 14/09",
  },
  {
    name: "tarifsPeakPrice",
    text: "20€",
  },
  // SERVICES
  {
    name: "servicesTitle",
    text: "SERVICES",
  },
  {
    name: "plugService",
    text: `Tous les emplacements disposent d'une prise électrique 4 AMP (880 w), pas de climatisation ou autres appareils énergivores possibles, uniquement pour la recharge de votre cellule`,
  },
  {
    name: "wifiService",
    text: `Vous disposerez d’un accès au wifi avec un très bon débit, le code d’accès vous sera remis sur votre ticket à l’entrée`,
  },
  {
    name: "waterService",
    text: `Un tuyau situé de chaque côté pour remplir facilement sans manoeuvre`,
  },
  {
    name: "cardService",
    text: `L'entrée et la sortie sur l'aire se font via une borne automatique à cartes bleues en toute autonomie, vous trouverez un panneau d’explication clairs afin de vous permettre d’entrée sur l’aire`,
  },
  {
    name: "recyclingService",
    text: `L'aire dispose de conteneurs de tri sélectifs ainsi que d'un composteur`,
  },
  {
    name: "toiletService",
    text: `Un bac pour vider les WC chimique avec rinçage et un bac de rinçage`,
  },
  // NEARBY
  {
    name: "nearbyTitle",
    text: "À PROXIMITÉ",
  },
  {
    name: "jardinTailleferTitle",
    text: "Jardin du Taillefer",
  },
  {
    name: "jardinTailleferContent",
    text: `Située au milieu des terres agricoles familiale exploitées en maraichage bio, vous trouverez sur place deux fois par semaines une vente de légumes bio (lundi et jeudi de 16h30 à 19h30) ainsi qu’un restaurant végétarien 100% local<br /><a href="https://www.jardins-du-taillefer.fr" target='_blank' rel='noopener noreferrer'>Plus d’info sur leur site</a>`,
  },
  {
    name: "hikingTitle",
    text: "Randonnées",
  },
  {
    name: "hikingContent",
    text: `De nombreuses randonnées au départ de l'aire afin de prendre un peu de hauteur et admirer le lac ou alors découvrir la réserve naturelle du bout du lac`,
  },
  {
    name: "bikeTitle",
    text: "Piste Cyclable",
  },
  {
    name: "bikeContent",
    text: `L'aire est très calme : à 300m de la piste cyclable reliant Annecy-Albertville et à 400m de la première plage du Lac d'Annecy`,
  },
  // REVIEW
  {
    name: "reviewTitle",
    text: "VOS AVIS",
  },
  {
    name: "seeMoreReviews",
    text: "VOIR PLUS D'AVIS",
  },
  // FOOTER
  {
    name: "signatureFooter",
    text: `<a href="https://sanb.fr" target='_blank' rel='noopener noreferrer'>Site Web par Sandro Brignoli 💭</a>`,
  },
];

export default frenchTexts;
