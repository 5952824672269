import { Stack, Box } from "@mui/system";

import Text from "./Text";

const NearbyCard = ({ title, image, text, reverse = false }) => {
  return (
    <Stack
      className='nearby-card'
      data-aos='fade-up'
      gap='2rem'
      m='auto'
      mb='2rem'
      color='white'
      display='flex'
      flexDirection='column'
      backgroundColor='primary.main'
      borderRadius='5rem'
      sx={[
        {
          width: { xs: "90%", sm: "70%", md: "80%", lg: "90%" },
          gap: { xs: "1rem", lg: "2rem" },
          p: { xs: "1rem", sm: "2rem" },
        },
      ]}
    >
      <Text textName={title} variant='h5' textAlign='center'></Text>
      <Stack
        sx={[
          reverse
            ? {
                flexDirection: { xs: "column", md: "row-reverse" },
              }
            : {
                flexDirection: { xs: "column", md: "row" },
              },
          {
            gap: {
              xs: "1rem",
              lg: "0",
            },
          },
        ]}
        justifyContent='space-evenly'
        alignItems='center'
      >
        <Box
          sx={[
            {
              width: { xs: "80%", md: "28rem", lg: "34rem" },
              height: { xs: "10rem", md: "15rem", lg: "20rem" },
            },
          ]}
          backgroundColor='transparent'
          borderRadius={"2rem"}
        >
          <img
            src={`./images/${image}`}
            alt={image}
            width='100%'
            height='100%'
            style={{
              borderRadius: "2rem",
            }}
          />
        </Box>
        <Text
          sx={[
            {
              width: { xs: "20rem", sm: "25rem", lg: "30rem" },
            },
          ]}
          textAlign='center'
          textName={text}
        ></Text>
      </Stack>
    </Stack>
  );
};

export default NearbyCard;
