import { useEffect } from "react";
import ReviewItem from "./ReviewItem";

import Carousel from "react-material-ui-carousel";

import { useSelector } from "react-redux";
import { useState } from "react";

import englishReviews from "../data/englishReviews";
import frenchReviews from "../data/frenchReviews";
import { Stack } from "@mui/system";
import TitleCard from "./TitleCard";
import { Button } from "@mui/material";
import Text from "./Text";

const Review = () => {
  const language = useSelector((store) => store.LANGUAGE.language);
  const [reviews, setReviews] = useState(frenchReviews);

  useEffect(() => {
    if (language === "english") {
      setReviews(englishReviews);
    } else {
      setReviews(frenchReviews);
    }
  }, [language]);

  return (
    <Stack
      sx={{
        padding: "0 0 1rem",
        marginBottom: "1rem",
      }}
    >
      <TitleCard textType='h5' text='reviewTitle' />
      <Carousel interval={3500} animation='fade'>
        {reviews.map((review) => (
          <Stack key={review.time} marginBottom='1rem'>
            <ReviewItem
              author={review.author_name}
              authorUrl={review.author_url}
              rating={review.rating}
              text={review.text}
              avatarUrl={review.profile_photo_url}
            />
          </Stack>
        ))}
      </Carousel>

      <Button
        variant='contained'
        sx={{ margin: "1rem auto 0", fontWeight: "bold", color: "white" }}
      >
        <a
          href='https://park4night.com/fr/place/99683'
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: "white" }}
        >
          <Text textName='seeMoreReviews' />
        </a>
      </Button>
    </Stack>
  );
};

export default Review;
