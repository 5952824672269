const englishReviews = [
  // GOOGLE REVIEWS
  {
    author_name: "stephen dennis",
    author_url:
      "https://www.google.com/maps/contrib/105847596690623415890/reviews",
    language: "en",
    original_language: "en",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a-/AD_cMMRziFIc1GYZj5q8D2mP56ZTez81jaINgl0SbU0T=s128-c0x00000000-cc-rp-mo-ba2",
    rating: 5,
    relative_time_description: "7 months ago",
    text: "Great Aires, reasonably level, grassed area, easy walking distance to lake Annecy and the beach at Doussard. Plenty of room for vehicles up to 8m, barrier entry system. Local restaurants etc within easy walking distance, we will defiantly be back.",
    time: 1668118738,
    translated: false,
  },
  {
    author_name: "PHILIPPE MORAND",
    author_url:
      "https://www.google.com/maps/contrib/114472659869150736507/reviews",
    language: "en",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AAcHTtfwta7UGFtVmxD3KKBdD8djZ5z2HQ6FQ9OvVm67=s128-c0x00000000-cc-rp-mo",
    rating: 5,
    relative_time_description: "a month ago",
    text: "Easy to access 24 hours a day, no waiting with automatic terminal and credit card, you pay the 1st night, if you stay several days you will pay the total number of nights when you leave, very simple.\nAround 15€/24h depending on the season, water, electricity and tourist taxes included.\nThe place is very quiet, with the end of the lake, and 200m from the greenway: Annecy to Albertville and more.\nYou are in the countryside with a view of the magnificent Alps.",
    time: 1683205412,
    translated: true,
  },
  {
    author_name: "ZEGE",
    author_url:
      "https://www.google.com/maps/contrib/100776793137572004916/reviews",
    language: "en",
    original_language: "de",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AAcHTtdnmSnypg9-YnyyhIGDEl16_cieEgeqsvRxmycwsg=s128-c0x00000000-cc-rp-mo-ba4",
    rating: 4,
    relative_time_description: "9 months ago",
    text: "Quiet pitch. Ideal for cycling and hiking. For caravans up to 8 m. Pleasant guests.",
    time: 1661671959,
    translated: true,
  },
  {
    author_name: "Pat Patrouille",
    author_url:
      "https://www.google.com/maps/contrib/102545080452791939920/reviews",
    language: "en",
    original_language: "fr",
    profile_photo_url:
      "https://lh3.googleusercontent.com/a/AAcHTteVMQP4RSIn9yYkom4YfLRgxF_fjEdbj2roHshd=s128-c0x00000000-cc-rp-mo",
    rating: 3,
    relative_time_description: "9 months ago",
    text: "Correct area for a stopover, calm well served with cycle path, only downside a little too close to each other",
    time: 1661242698,
    translated: true,
  },
  // PARK4NIGHT REVIEWS
  {
    author_name: "nabe13",
    author_url: "https://park4night.com/en/user/nabe13",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 5,
    text: "everything was perfect, the pitches, the proximity to the bike path and the lake, the well-designed services (emptying, water, etc.) above all, continue to maintain your area well.",
    time: 1776758037,
  },
  {
    author_name: "lesjoemi",
    author_url: "https://park4night.com/en/user/lesjoemi",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_p.png?v=b30ff3b",
    rating: 4,
    text: "Very pleasant area with all the services, we spent 2 superb nights. Beach and greenway not far. Too bad, some pitches are a little steep. Right price.",
    time: 1776758038,
  },
  {
    author_name: "LADGUY",
    author_url: "https://park4night.com/en/user/LADGUY",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_gv.png?v=b30ff3b",
    rating: 5,
    text: "very nice place to stay very quiet during the day and night as its just off the main road. manager very welcoming. Area by gate to fill and empty water and waste. also bins provided. would stay again.",
    time: 1776758039,
  },
  {
    author_name: "TomBrookes",
    author_url: "https://park4night.com/en/user/TomBrookes",
    profile_photo_url:
      "https://cdn6.park4night.com/images/bitmap/vehicules/vehicule_nc.png?v=b30ff3b",
    rating: 5,
    text: "A fantastic Aire in a fantastic spot. Excellent facilities.",
    time: 1776758040,
  },
];

export default englishReviews;
