import { useState } from "react";
import { Box } from "@mui/material";
import { Stack } from "@mui/system";
import BackgroundVideo from "./BackgroundVideo";
import Info from "./Info";
import Map from "./Map";

const Hero = () => {
  const backgroundImage = "./images/hero.jpg";

  return (
    <Stack
      direction='row'
      backgroundColor='rgb(177, 177, 177)'
      justifyContent='flex-start'
      width='100%'
      height='100vh'
      sx={{
        position: "relative",
      }}
    >
      <BackgroundVideo
        videoUrl={`https://www.youtube.com/watch?v=l75a9ZLQpv0`}
        fallbackImage={backgroundImage}
      />
      <Stack
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
          zIndex: 1,
        }}
      />
      <Stack
        direction='column'
        position='relative'
        sx={{
          width: { xs: "95%", sm: "30rem" },
          height: { xs: "30rem", sm: "25rem", lg: "35rem" },
          ml: { sm: "10rem", lg: "15rem" },
          mt: { xs: "12rem", sm: "6rem", lg: "12rem" },
          m: { xs: "12rem auto 0" },
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            transform: "translate(50%, -50%)",
            bottom: { xs: "45%", sm: "10%", lg: "50%" },
            right: { xs: "50%", sm: "100%", lg: "50%" },
          }}
          position='absolute'
          height='fit-content'
          borderRadius='50%'
          p='1rem'
          backgroundColor='white'
          zIndex={1}
        >
          <img src='./logo.png' alt='Logo' width='260rem' height='260rem' />
        </Box>
        <Map>
          <Info />
        </Map>
      </Stack>
    </Stack>
  );
};

export default Hero;
