import { createSlice } from "@reduxjs/toolkit";

export const languageSlice = createSlice({
  name: "languageSlice",
  initialState: {
    language: "french",
  },
  reducers: {
    changeLanguage: (currentSlice, action) => {
      currentSlice.language = action.payload;
    },
  },
});

export const { changeLanguage } = languageSlice.actions;
