import { configureStore } from "@reduxjs/toolkit";
import { languageSlice } from "./language/language-slice";

const store = configureStore({
  reducer: {
    LANGUAGE: languageSlice.reducer,
  },
});

export { store };
