import { Box, Link, Typography } from "@mui/material";

const InfoItem = ({ icon, text, isMail = false }) => {
  const Icon = icon;
  let textContent = text;

  isMail &&
    (textContent = (
      <Link
        sx={{ color: "white", textDecoration: "underline" }}
        href={`mailto:${text}`}
      >
        {text}
      </Link>
    ));

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <Icon fontSize='large' htmlColor='white' />
      <Typography
        sx={{ color: "white", fontSize: { xs: "0.95rem", sm: "1rem" } }}
      >
        {textContent}
      </Typography>
    </Box>
  );
};

export default InfoItem;
