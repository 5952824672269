import { Stack } from "@mui/system";

import Text from "./Text";

const ServiceCard = ({ icon, text }) => {
  return (
    <Stack
      direction='column'
      alignItems='center'
      gap='1rem'
      data-aos='zoom-out'
      data-aos-mirror='true'
      data-aos-once='true'
    >
      <img
        src={`./picto/${icon}.svg`}
        alt={"icon"}
        width='200rem'
        height='200rem'
      />
      <Text style={{ width: "fit-content" }} textName={text} />
    </Stack>
  );
};

export default ServiceCard;
