import { Avatar, Grid, Card, Rating, Typography } from "@mui/material";

const ReviewItem = ({ author, authorUrl, rating, text, avatarUrl }) => (
  <Card
    sx={{
      width: { xs: "90%", sm: "50%" },
      display: "flex",
      margin: "auto",
      padding: "2rem 1rem",
    }}
  >
    <a href={authorUrl} target='_blank' rel='noopener noreferrer'>
      <Avatar alt={author} src={avatarUrl} sx={{ marginRight: "16px" }} />
    </a>
    <div>
      <Grid container flexDirection='column'>
        <Grid item>
          <a href={authorUrl} target='_blank' rel='noopener noreferrer'>
            <Typography variant='body1' sx={{ fontWeight: "bold" }}>
              {author}
              <br />
            </Typography>
          </a>
        </Grid>
        <Grid item sx={{ marginRight: "8px" }}>
          <Rating value={rating} readOnly />
        </Grid>
      </Grid>
      <Typography
        variant='body2'
        sx={{
          width: { xs: "90%", sm: "100%" },
          margin: "auto",
          fontStyle: "italic",
        }}
      >
        {text}
      </Typography>
    </div>
  </Card>
);

export default ReviewItem;
